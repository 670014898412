import React, { useState, useEffect } from "react";
import { debug } from "utils";
import { FaPlus } from "react-icons/fa";
import './checkboxes-with-custom-input.css';
import { Checkbox } from "react-bootstrap";
const CheckboxesWithCustomInput = ({ options, name, value, allowAdd = false, type = "checkbox", onChange }) => {
  // alert(value)
  const [selectedOptions, setSelectedOptions] = useState(

    Array.isArray(value) ? value : value ? [value] : []
  );

  const [customOptions, setCustomOptions] = useState(options);
  const [customInput, setCustomInput] = useState("");
  useEffect(() => {
    let normalizedValue;

    if (type === "checkbox") {
      // Normalisation pour les cases à cocher (convertir en tableau si nécessaire)
      normalizedValue = Array.isArray(value) ? value : value ? [value] : [];
    } else if (type === "radio") {
      // Normalisation pour les boutons radio (doit être une chaîne ou null)
      normalizedValue = value || "";
    } else {
      return; // Si ce n'est ni checkbox ni radio, on sort
    }

    // Vérifier les options manquantes (uniquement pour les cases à cocher)
    if (type === "checkbox") {
      const missingOptions = normalizedValue.filter(
        (item) => !customOptions.includes(item)
      );

      if (missingOptions.length > 0) {
        setCustomOptions((prevOptions) => [...prevOptions, ...missingOptions]);
      }
    }
  }, [value, type, customOptions]);


  /*
  useEffect(() => {
    const missingOptions = selectedOptions.filter(
      (item) => !customOptions.includes(item)
    );
    if (missingOptions.length > 0) {
      setCustomOptions((prev) => [...prev, ...missingOptions]);
    }
  }, [selectedOptions, customOptions]);
  */
  const handleCheckboxChange = (value) => {
    const isSelected = selectedOptions.includes(value);

    // Vérifie si "aucune" ou "aucun" est sélectionné (insensible à la casse)
    const isNoneSelected = /^aucun(e)?$/i.test(value);

    let updatedSelection;

    if (type === "checkbox") {
      if (isNoneSelected) {
        // Si on sélectionne "aucun(e)", on remplace toute la sélection par [value]
        updatedSelection = [value];
      } else {
        // Sinon, on ajoute ou retire normalement
        updatedSelection = isSelected
          ? selectedOptions.filter((item) => item !== value)
          : [...selectedOptions, value];
        // Si on ajoute une option et "aucun(e)" était déjà sélectionné, on le retire
        updatedSelection = updatedSelection.filter(
          (item) => !/^aucun(e)?$/i.test(item)
        );
      }
    } else {
      updatedSelection = value; // Pour les radios, juste affecter la valeur
    }
    console.log("Updated selection:", updatedSelection);
    setSelectedOptions(updatedSelection);
  };
  const handleAddCustomValue = () => {
    if (customInput.trim() && !customOptions.includes(customInput)) {
      setCustomOptions((prev) => [...prev, customInput]);
      setSelectedOptions((prev) => [...prev, customInput]);
    }
  };
  useEffect(() => {
    console.log('useEffect', name)
    onChange({ target: { name: name, value: selectedOptions } })
  }, [selectedOptions])
  return (
    <div className="d-flex flex-column gap-1 mb-2 align-items-stretch checkboxes-with-custom-input">
      <div className="options">
        {customOptions.map((option, index) => (
          <div key={index} className="option">
            <label className="checkbox-label">
              <input
                type={type === 'radio' ? 'radio' : 'checkbox'}
                className=""
                name={name}
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />

              {option}
            </label>
          </div>
        ))}

      </div>
      {allowAdd &&
        <div className="input-group input-group-xs w-100" style={{ maxWidth: "240px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Ajouter une nouvelle valeur"
            value={customInput}
            onChange={(e) => setCustomInput(e.target.value)}
          />
          <button
            className="btn btn-primary"
            type="button"
            onClick={handleAddCustomValue}
          >
            <FaPlus />
          </button>
        </div>
      }
    </div>
  );
};
export default CheckboxesWithCustomInput;