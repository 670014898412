import { useState, useEffect } from "react";

const YearSelector = ({ name, value, onChange, minYear = 2000, maxYear = 2100, size = "sm" }) => {
  // Définir la valeur initiale sur l'année en cours si aucune valeur n'est fournie
  const currentYear = new Date().getFullYear();
  const initialYear = value ?? (currentYear >= minYear && currentYear <= maxYear ? currentYear : minYear);

  const [year, setYear] = useState(initialYear);

  useEffect(() => {
    if (value !== undefined) {
      setYear(value);
    }
  }, [value]);

  const handleChange = (newYear) => {
    if (newYear >= minYear && newYear <= maxYear) {
      setYear(newYear);
      if (onChange) {
        onChange({ target: { name, value: newYear } });
      }
    }
  };

  return (
    <div className={`input-group input-group-${size} w-100`} >
      <input
        type="number"
        className="form-control text-center"
        name={name}
        value={year}
        readOnly
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={() => handleChange(year - 1)}
        disabled={year <= minYear}
      >
        -
      </button>

      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={() => handleChange(year + 1)}
        disabled={year >= maxYear}
      >
        +
      </button>
    </div>
  );
};

export default YearSelector;
