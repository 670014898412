import Picture from 'components/helpers/Picture/Picture';
import React, { useState, useEffect, use } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HtmlElement } from '../../../../../helpers/HtmlElements/HtmlElements';
import { debug, formatDate, isDev, swalAlert, swalInput } from 'utils';
import API from 'api';
const TreeFormTasks = ({ item, onChange }) => {
  useEffect(() => {
  }, []);
  const handleChange = (event) => {
    onChange(event)
  }
  return (

    item && item.tasks &&
    <div id="TreeFormTasks">
      <div className='d-flex flex-wrap'>
        <div className='card  card-tree-interventions mb-3'>
          <h6 className='card-title mb-1'>Interventions sur l'arbre</h6>
          <div className='card-body'>
            <div className='form-group tree-interventions'>
              <label className='form-group-label'>
                Interventions à réaliser :
              </label>
              <HtmlElement.checkboxesWithCustomInput
                onChange={handleChange}
                options={[
                  "Aucune",
                  "Abattage",
                  "Taille de gabarit",
                  "Taille de cohabitation",
                  "Taille aux anciennes coupes",
                  "Haubanage/brochage",
                ]}
                name="tasks.treeInterventions"
                value={item.tasks.treeInterventions}
                allowAdd={true}
              />
            </div>
            <div className='form-group  tree-interventions-delay'>
              <label className='form-group-label mb-2'>
                Délai d'intervention :
              </label>
              <div className='d-flex'>
                <div className='mb-2 w-50  pe-1'>
                  <HtmlElement.select
                    name="tasks.treeInterventionsSeason"
                    value={item.tasks.treeInterventionsSeason}
                    onChange={handleChange}
                    size='xs'
                    options={[
                      '',
                      'Urgent',
                      'Hiver',
                      'Printemps',
                      'Eté',
                      'Automne',
                    ]}
                  /></div>
                <div className='mb-2 w-50 ps-1'>
                  <HtmlElement.yearSelector
                    size='xs'
                    name="tasks.treeInterventionsYear"
                    onChange={handleChange}
                    value={item.tasks.treeInterventionsYear}
                  />
                </div>

              </div>

            </div>
          </div>

        </div >
        <div className='card card-remanents mb-3'>
          <h6 className='card-title mb-1'>Gestion des rémanents :</h6>
          <div className='card-body'>
            <div className='form-group '>
              <HtmlElement.checkboxesWithCustomInput
                options={[
                  "Aucune",
                  "Broyage des branches",
                  "Gros bois à évacuer",
                  "Rognage de la souche",
                  "Conservation d'un tronc après abattage",
                ]}
                name="tasks.remanents"
                value={item.tasks.remanents}
                allowAdd={true} // Syst ouvert => true
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-wrap'>
        <div className='card  card-site-interventions mb-3'>
          <h6 className='card-title mb-1'>Intervention sur le site :  </h6>
          <div className='card-body'>
            <div className='form-group'>
              <label className='form-group-label'>
                Interventions à réaliser :
              </label>
              <HtmlElement.checkboxesWithCustomInput
                onChange={handleChange}
                options={[
                  "Aucune",
                  "Paillage",
                  "Deplacer la cible",
                  "Périmètre de sécurité",
                  "Suivi tuteurs et liens"
                ]}
                name="tasks.siteInterventions"
                value={item.tasks.siteInterventions}
                allowAdd={true}
              />
            </div>
            <div className='form-group'>
              <label className='form-group-label mb-2'>
                Délai d'intervention :
              </label>
              <div className='d-flex'>
                <div className='mb-2 w-50  pe-1'>
                  <HtmlElement.select
                    name="tasks.siteInterventionsSeason"
                    value={item.tasks.siteInterventionsSeason}
                    onChange={handleChange}
                    size='xs'
                    options={[
                      '',
                      'Urgent',
                      'Hiver',
                      'Printemps',
                      'Eté',
                      'Automne',
                    ]}
                  /></div>
                <div className='mb-2 w-50 ps-1'>
                  <HtmlElement.yearSelector
                    size='xs'
                    name="tasks.siteInterventionsYear"
                    onChange={handleChange}
                    value={item.tasks.siteInterventionsYear}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className='card card-remanents mb-3'>
          <h6 className='card-title mb-1'>A surveiller :</h6>
          <div className='card-body'>
            <div className='form-group '>
              <HtmlElement.checkboxesWithCustomInput
                options={[
                  "Aucun",
                  "Evolution physiologique",
                  "Evolution mécanique",
                  "Evolution dégradation",
                  "Evolution cavité",
                  "Evolution insertion incluse"
                ]}
                name="tasks.toWatch"
                value={item.tasks.toWatch
                }
                allowAdd={true} // Syst ouvert => true
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-wrap '>
        <div className='card  card-examinations mb-3'>
          <h6 className='card-title mb-1'>Examens :  </h6>
          <div className='card-body'>
            <div className='form-group'>
              <label className='form-group-label'>
                Examens réalisés :
              </label>
              <HtmlElement.checkboxesWithCustomInput
                onChange={handleChange}
                options={[
                  "Aucun",
                  "Carottage",
                  "Résistographe",
                  "Tomographie",
                  "Tests de traction",
                  "Excavation racinaire",
                  "Examen sonore"
                ]}
                name="tasks.examinationsDone"
                value={item.tasks.examinationsDone}
                allowAdd={true}
              />
            </div>
            <div className='form-group'>
              <label className='form-group-label mb-2'>
                Examens à réaliser :
              </label>

              <div className='mb-2  pe-1'>
                <HtmlElement.checkboxesWithCustomInput
                  name="tasks.examinationsToDo"
                  value={item.tasks.examinationsToDo}
                  onChange={handleChange}
                  size='xs'
                  allowAdd={true}
                  options={[
                    'Aucun',
                    "Contrôle visuel",
                    "Examen en hauteur",
                    "Excavation racinaire",
                    "Recherche de pathogène",
                    "Résistographe"]}
                /></div>


            </div>
            <div className='form-group nextExamination'>
              <label className='form-group-label mb-2'>
                Prochain examen dans :
              </label>

              <div className='mb-2  pe-1 '>
                <HtmlElement.checkboxesWithCustomInput
                  name="tasks.nextExamination"
                  value={item.tasks.nextExamination}
                  onChange={handleChange}
                  className='nextExamination'
                  size='xs'
                  type="radio"
                  allowAdd={true}
                  options={[
                    "6 mois",
                    "1 an",
                    "2 ans",
                    "3 ans",
                    "4 ans",
                    "5 ans",
                    "6 ans",
                    "7 ans",
                    "8 ans",
                    "9 ans",
                    "10 ans"]}
                /></div>


            </div>
          </div>
        </div >
      </div>
      <div className='d-flex flex-wrap '>
        <div className='card  card-comments mb-3'>
          <h6 className='card-title mb-1'>Remarque</h6>
          <div className='card-body'>
            <div className='form-group'>
              <HtmlElement.textarea
                onChange={handleChange}
                name="tasks.comments"
                value={item.tasks.comments}
              />
            </div>
          </div>

        </div >
        <div className='card card-maintain mb-3'>
          <h6 className='card-title mb-1'>Arbre à maintenir : </h6>
          <div className='card-body'>
            <div className='form-group '>
              <HtmlElement.checkboxesWithCustomInput
                options={[

                  "Oui",
                  "Non",
                ]}
                name="tasks.treeMaintain"
                value={item.tasks.treeMaintain}
                type='radio'
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      {debug(item.tasks)}
    </div >
  )
}
export default TreeFormTasks;

