import React, { useMemo, useEffect, useState } from "react";
import { debug, swalConfirm } from 'utils';
import API from 'api';
import { useTranslation } from "react-i18next";
import { HtmlElement } from './../../../../../helpers/HtmlElements/HtmlElements';
import { FaTrash } from "react-icons/fa";
import SymptomsSelector from "../helpers/SymptomsSelector";
import AdaptationsSelector from "../helpers/AdaptationsSelector";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlinePlusCircle } from "react-icons/ai"
import Indicator from "../helpers/Indicator";

const TreeFormPhysiology = ({ item, onChange }) => {

  const [physilogyMatix, setPhysilogyMatix] = useState({});
  const [itemCopy, setItemCopy] = useState(item);
  const { t } = useTranslation();

  const fetchPhysilogyMatix = async () => {
    const response = await API.get('/tree/physilogyMatix');
    setPhysilogyMatix(response.data);
  };
  useEffect(() => {
    fetchPhysilogyMatix();
  }, []);
  useEffect(() => {
    if (typeof item.meta['physiology/presence_reiterations'] == 'undefined') {
      // onChange({ target: { name: `meta.physiology/presence_reiterations`, value: "Aucune" } });
    }
    if (item.meta['physiology/presence_reiterations'] != itemCopy.meta['physiology/presence_reiterations'] && item.meta['physiology/presence_reiterations'] == 'Aucune') {
      //onChange({ target: { name: `meta.physiology/qualite_reiterations`, value: "" } });
    }
    setItemCopy(item);
  }, [item]);

  return (
    <div id="TreeFormPhysiology">
      <div className="card">
        <h6 className="card-title mb-1" onClick={fetchPhysilogyMatix}>Physiologie</h6>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
              <label className="form-group-label mb-1">Stade de développement : </label>
              <HtmlElement.select
                options={["", "Jeune", "Adulte", "Mature", "Sénescent", "Vétéran"]}
                name={`meta.physiology/developmentStage`}
                value={item.meta['physiology/developmentStage'] || ""}
                onChange={onChange}
                size="xs"
              />
            </div>
          </div>
          <div className="row">
            {Object.entries(physilogyMatix).map(([key, data]) => (
              <div key={key} className={`col-12 col-sm-6 mb-3 ${key}`}>
                <label className="form-group-label mb-1">{data.text} : </label>
                <HtmlElement.select
                  options={[{ value: "", label: "" }, ...data.items.map((item) => ({
                    value: item.value,
                    label: `${item.value} (${item.description})`,
                  }))]}
                  name={`meta.physiology/${key}`}
                  value={item.meta[`physiology/${key}`] || ""}
                  onChange={onChange}
                  disabled={key === "qualite_reiterations" && item.meta[`physiology/presence_reiterations`] === "Aucune"}
                  size="xs"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {debug(item.meta, 1)}
    </div>
  );
};

export default TreeFormPhysiology;
