// HtmlElements.js
import LeafCheckbox from '../LeafCheckbox/LeafCheckbox';
import CheckboxesWithCustomInput from '../CheckboxesWithCustomInput/CheckboxesWithCustomInput';

import Switcher from '../Switcher/Switcher';
import { debug } from './../../../utils'
import YearSelector from './../YearSelector/YearSelector';

export const HtmlElement = {
  submit: ({
    type = 'button',
    name = 'name',
    className = 'btn-outline-primary',
    text = 'Sauvegarder',
    size = "sm",
    ...rest
  }) => (
    <button
      type={type}
      className={`btn ${className} ${size ? `btn-${size}` : ''}`.trim()}
      {...rest}
    >
      {text}
    </button>
  ),
  cancel: ({
    type = 'button',
    className = 'btn-outline-primary',
    text = 'Annuler',
    size = "sm",
    ...rest

  }) => (
    <button
      type={type}
      className={`${className} ${size ? `btn-${size}` : ''}`.trim()}
      {...rest}

    >
      {text}
    </button>
  ),
  checkbox: ({ className = '', label = '', ...rest }) => (
    <div className='d-inline-block  me-3'>
      <label className={`checkbox-label ${className} d-flex align-center`} >
        <input type="checkbox" className={`${className} ${label != '' ? 'me-2' : ''}`} {...rest} />{label}
      </label>
    </div>
  ),

  radio: ({ className = '', label = '', name = '', ...rest }) => (
    <div className='d-inline-block  me-3'>
      <label className={`checkbox-label ${className} d-flex align-center`}>
        <input type="radio" name={`${name}`} className={`${className} ${label != '' ? 'me-2' : ''}`} {...rest} />{label}
      </label>
    </div>
  ),

  leafCheckbox: ({ className = '', label = '', ...rest }) => (
    <label className={`checkbox-label ${className}`}>
      <LeafCheckbox className={className} {...rest} />{label}
    </label>
  ),

  input: ({ className = 'form-control form-control-sm', label = '', ...rest }) => {
    return (<input className={className} {...rest} ></input>)
  },
  text: ({ className = 'form-control form-control-sm', label = '', ...rest }) => {
    return (<input className={className} {...rest} ></input>)
  },
  textarea: ({ className = 'form-control form-control-sm', label = '', ...rest }) => (
    <textarea className={className} {...rest} ></textarea>
  ),

  select: ({ className = '', options = [], allowEmpty = false, placeholder = '', size = 'sm', ...rest }) => (
    <select className={`form-select form-select-${size} ${className}`} {...rest}>
      {(allowEmpty || placeholder) && (
        <option value="" disabled={!allowEmpty}>
          {placeholder || ''}
        </option>
      )}
      {options.map((opt, idx) => {
        if (typeof opt === 'object' && opt !== null && !Array.isArray(opt)) {
          return (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          );
        } else {
          return (
            <option key={idx} value={opt}>
              {opt}
            </option>
          );
        }
      })}
    </select>
  ),
  checkboxesWithCustomInput: ({ className = '', ...rest }) => (
    <CheckboxesWithCustomInput className={className} {...rest} />
  ),
  yearSelector: ({ className = 'form-control form-control-sm', ...rest }) => (
    <YearSelector className={className} {...rest} />
  ),
  formGroup: ({
    type = 'text',
    className = 'formGroup',
    size = 'sm',
    label = '',
    inputClassName = '',
    onChange = null,
    name = '',
    value = '',
    required = false,
    options = [],
    style = null,
    colorOptions = [
      { value: "", label: "Aucune" }, // Transparent
      { value: "#3d5e33", label: "Par défault" }, // Vert foncé
      { value: "#000000", label: "Noir" }, // Noir
      { value: "#800000", label: "Rouge" }, // Rouge brûlé
      { value: "#008000", label: "Vert" }, // Vert forêt
      { value: "#808000", label: "Jaune" }, // Jaune olive
      { value: "#000080", label: "Bleu" }, // Bleu marine
      { value: "#800080", label: "Violet" }, // Violet prune
      { value: "#008080", label: "Cyan" }, // Cyan foncé
      { value: "#808080", label: "Gris" } // Gris ardoise
    ],
    allowEmpty = true,
    ...rest
  }) => (
    <div className={`form-group ${size ? 'form-group-' + size : null} ${className}`}>
      {label && <label className={`form-group-label ${required ? 'required' : ''}`}>
        {label}
      </label>}
      {['text', 'date'].indexOf(type) > -1 &&
        <input
          type={type}
          className={`form-control ${size ? 'form-control-' + size : null}  ${inputClassName} `}
          name={name}
          onChange={onChange}
          value={value}
          {...(style ? { style } : {})}
          {...rest}
        />}

      {type == 'select' &&
        <select
          type={type}
          className={`form-control ${size ? 'form-control-' + size : null}  ${inputClassName} `}
          name={name}
          onChange={onChange}
          value={value}
          {...(style ? { style } : {})}
          {...rest}
        >
          {allowEmpty == true &&
            <option></option>
          }
          {options.map((opt, idx) => (
            <option key={idx} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>}
      {type == 'textarea' &&
        <textarea
          className={`form-control ${size ? 'form-control-' + size : null}  ${inputClassName} `}
          name={name}
          onChange={onChange}
          value={value}
          {...(style ? { style } : {})}
          {...rest}
        />}
      {type == 'colorSelect' &&
        <select
          className={`form-control ${size ? 'form-control-' + size : null}  ${inputClassName} `}
          value={value}
          name={name}
          onChange={onChange}
          {...rest}
        >
          {colorOptions.map(option => (
            (option.value !== '' || allowEmpty == true) &&
            <option key={option.value} value={option.value} style={{ borderLeft: '2px solid ' + option.value }}>
              {option.label}
            </option>
          ))}
        </select>
      }
    </div>
  ),
};
